import React, {useState} from 'react'
import { ClickAwayListener } from '@mui/material'

export default function TextArea({value, handleChange, placeholder}) {

    const [active, setActive] = useState(false)

  const toggleActive = () => setActive(true)

  const toggleInactive = () => setActive(false)

  return (
    <ClickAwayListener onClickAway={toggleInactive}>
    <div className = 'lessons-section'>
      <div onClick = {toggleActive} className={`lessons-textarea ${active ? 'textarea-active' : ""}`}>
        <div>
          <textarea
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  </ClickAwayListener>
  )
}
