import React from 'react'

export default function Technique({title, howUse, whatIs, benefits}) {

    
    return (
    <div className='technique'>
        <h2>{title}</h2>
        <div className='technique-content'>
            <div className='what-is'>
                <h3>What is {title}:</h3>
                <p>{whatIs}</p>
            </div>
            <div className='how-use'>
                <h3>How to Use It:</h3>
                <ol type='1'>
                    {howUse.map((item, index) => {
                        return <li key={index}>{item}</li>
                    })}
                </ol>
            </div>
            <div className='benefits'>
                <h3>Benefits of the Technique:</h3>
                <ol>
                    {benefits.map((item, index) => {
                        return <li key={index}>{item}</li>
                    })}
                </ol>
            </div>
        </div>
        <p></p>
    </div>
  )
}
