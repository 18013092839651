import React, {useState} from 'react'
import { TextField} from '@mui/material'
import {createTheme, ThemeProvider} from '@mui/material' 
import Switch from '../Switch';


export default function Description({error, handleGenerate, width, planType}) {

    const [data, setData] = useState({
        post: "",
        hashtags: "",
        length: "",
    })
    const [switchOn, setSwitchOn] = useState(false)

    const toggleSwitch = () => setSwitchOn(prevState => !prevState)

    const theme = createTheme({
        components: {
          MuiTextField: {
            styleOverrides: {
              root: {
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.3)', // Customize the border color
                  },
                  '&:hover fieldset': {
                    borderColor: 'var(--primary-color)', // Customize the active border color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor:'var(--primary-color)' // Customize the active border color
                  },
                },
              },
            },
          },
        },
      });
    
      const handleChange = (event) => {
        const {name, value} = event.target
        
        setData(prevState => ({
            ...prevState,
            [name]:value
        }))

      }
  return (
    <section className='equipment-section'>
      <h3 className='tool-description'>Social media descriptions generator</h3>
        <ThemeProvider theme={theme}>
            <TextField
              name='post'
              onChange={handleChange}
              value={data.post}
              className='text-input'
              placeholder='Describe your post' 
            />
            
            <div className='secondary-section'>
                <TextField  
                    name='length'
                    onChange={handleChange}
                    value={data.length}
                    className='text-input'
                    placeholder='Length (words)'
                    type='number'
                />
                
                <TextField  
                    name='hashtags'
                    onChange={handleChange}
                    value={data.hashtags}
                    className='text-input'
                    placeholder='Hashtags number'
                    type='number'
                />
            </div>
            <div className='generate-button-container'>   
              <button onClick = {() => handleGenerate(data.post, data.hashtags, data.length, switchOn)} className='generate-button'><i className="fa-solid fa-circle-down"></i>Generate Description<i className="fa-solid fa-circle-down"></i></button>
              {planType && planType.split(" ")[1] !== "Premium" && <div className='rocket-container'>
                <Switch width = {width} switchOn={switchOn} toggleSwitch={toggleSwitch}/>
              </div>}
            </div>
            {error && <span className='error'>{error}</span>}
        </ThemeProvider>
    </section>
  )
}
